.productCat {
  margin: 50px 0;
  .category {
    border-radius: 10px;
    border: 1px solid rgb(214, 214, 214);
    padding: 30px 30px 0;
    height: 275px;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    display: block;
    h2 {
      margin: 0;
      width: 60%;
      text-transform: uppercase;
      font-size: 23px;
      color: black;
    }
    h3 {
      margin: 3px 0 0;
      font-size: 16px;
      color: black;
    }
    p {
      width: 60%;
      margin: 20px 0 25px;
      text-align: justify;
      line-height: 22px;
      color: black;
    }
  }
}

@media screen and (max-width: 1024px) {
  .productCat {
    .category {
      h2 {
        width: 100%;
      }
      p {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .productCat {
    .category {
      margin-bottom: 15px;
      height: auto;
      padding-bottom: 28px;
      h2 {
        width: 100%;
      }
      p {
        width: 100%;
      }
    }
  }
}
