@import "../../variables.scss";

.contact {
  .contentSection {
    margin: 10px 0 50px;
    .contactBlock {
      text-align: center;
      margin-top: 40px;
      box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.2);
      padding: 40px 20px;
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
      background: url("../../../public/images/bgcontact.png") 0 0 no-repeat,
        #5b711c;
      background-size: cover;
      h3 {
        font-size: 25px;
        text-transform: none;
        color: #ffffff;
        margin-bottom: 10px;
      }
      p {
        color: #ffffff;
        margin-top: 0;
      }
      .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        padding: 10px;
        overflow: hidden;
      }
    }
    .contactForm {
      margin: 70px 0 0;
      h1 {
        text-align: center;
        font-size: 28px;
        margin-bottom: 25px;
      }
      .ant-input {
        background: #efefef;
        border-radius: 0;
        padding: 10px;
      }
      textarea {
        resize: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .contact {
    .contentSection {
      padding: 0 15px;
    }
  }
}
