@import "../../variables.scss";

.blogs {
  margin: 50px 0;
  .blog {
    display: block;
    a {
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
    img {
      width: 94%;
      max-height: 287px;
    }
    cursor: pointer;
    .blogContent {
      width: 70%;
      background: #ffffff;
      margin-top: -80px;
      padding: 20px;
      margin-bottom: 30px;
      box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.2);
      p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 14px;
        .anticon-calendar {
          color: $green-color;
          margin-right: 4px;
        }
      }
      h2 {
        margin: 0;
        font-size: 17px;
        color: #000000;
      }
      .bottomSection {
        margin-top: 20px;
        border-top: 1px solid #eaeaea;
        padding-top: 15px;
        position: relative;
        display: flex;
        justify-content: space-between;
        &::before {
          content: "";
          background: $green-color;
          width: 40px;
          height: 2px;
          position: absolute;
          top: -3px;
          left: 0;
        }
        .primary_btn {
          background: transparent;
          color: $primary-color;
          box-shadow: none;
          padding: 0;
          text-transform: capitalize;
          height: auto;
          .anticon-right {
            color: $green-color;
            margin-left: 3px;
            font-size: 10px;
          }
        }
        .comments {
          font-size: 14px;
          margin-top: 2px;
          color: $paraghraph-color;
          .anticon-comment {
            color: #f07611;
          }
        }
      }
    }
  }
}
