.productList {
  h1 {
    text-align: center;
    margin: 50px 0 0;
    margin-bottom: 35px;
    font-size: 25px;
  }
  .product {
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    padding-bottom: 15px;
    background: #ffffff;
    cursor: pointer;
    display: block;
    img {
      height: 230px;
    }
    h2 {
      margin: 15px 0 0;
      font-size: 20px;
      color: #000000;
    }
    p {
      margin: 5px 0 10px;
      color: #0e9210;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 17px;
    }
  }
}

@media screen and (max-width: 767px) {
  .productList {
    .product {
      margin-bottom: 30px;
    }
  }
}
