.counter {
  background: #000000;
  color: #ffffff;
  position: absolute;
  top: 65px;
  right: 0;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 2px 2px 13px rgba($color: #000000, $alpha: 0.8);
  p {
    color: #ffffff;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    span {
      font-size: 25px;
      font-weight: bold;
      color: #ffff00;
    }
  }
}

@media screen and (max-width: 767px) {
  .counter {
    display: none;
  }
}
