@import "../../variables.scss";

.productList.products {
  background: transparent;
  padding: 0px 0 80px;
}
.products {
  h1 {
    text-align: left;
  }
  .product {
    box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.2);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .ant-tabs {
    font-size: 16px;
    .ant-tabs-tab {
      font-size: 20px;
      font-weight: bold;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab:hover {
      color: $green-color;
    }
    .ant-tabs-ink-bar {
      background: $green-color;
    }
  }
}
