@import "../../variables.scss";

.testimonials {
  .contentSection {
    margin: 10px 0 50px;
    .comment {
      text-align: center;
      margin-top: 40px;
      box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.2);
      padding: 20px;
      h3 {
        font-size: 16px;
        text-transform: none;
      }
      .userPic {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $orange-color;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        padding: 10px;
        border: 10px solid #ffffff;
        outline: 2px solid $green-color;
        overflow: hidden;
      }
    }
  }
}
