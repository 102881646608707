@import "../../variables.scss";

.headerBar {
  background: $green-color;
  height: auto;
  margin-bottom: 15px;
}
.topBar {
  height: auto;
  align-items: center;
  padding: 0 10px;
  p {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
  }
  .anticon-facebook {
    color: #ffffff;
  }
  .facebook {
    text-align: right;
  }
}

.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background: $green-color;
  color: #ffffff;
}
.ant-menu .ant-menu-submenu-title .anticon {
  font-size: 30px;
  color: $green-color;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-color: $green-color;
}
@media screen and (min-width: 1200px) {
  .headerBar {
    height: 30px;
  }
  .topBar {
    padding: 0;
    height: 30px;
  }
}
.mainHeader {
  padding-bottom: 15px;
  margin-bottom: 4px;
  border-bottom: 1px solid rgb(216, 216, 216);
  .logo {
    img {
      width: 77px;
    }
  }
  .phoneNumber {
    display: flex;
    justify-content: right;
    align-items: center;
    .anticon-phone {
      font-size: 32px;
      margin-right: 5px;
      rotate: 100deg;
      opacity: 0.7;
      margin-top: 4px;
    }
    p {
      text-transform: uppercase;
      font-size: 18px;
      color: $primary-color;
      font-weight: bold;
      strong {
        color: gray;
        font-size: 13px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mainHeader {
    .logo {
      img {
        width: 55px;
        margin-left: 15px;
      }
    }
    .phoneNumber {
      display: none;
    }
  }
}
