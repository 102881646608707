.carousel .slide {
  text-align: left;
}
.slider {
  li {
    height: 588px;
    background: gray;
    position: relative;
    .slideContent {
      position: absolute;
      width: 100%;
      padding: 0 15px;
    }
    .centerText {
      display: flex;
      align-items: center;
      .slideContent {
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        h1 {
          color: #ffffff;
          font-size: 65px;
          margin-bottom: 20px;
          margin-top: 0;
        }
      }
    }
    .sideContext {
      display: flex;
      align-items: center;
      h1 {
        margin-bottom: 5px;
        margin-top: 0;
        font-size: 60px;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 38px;
      }
      p {
        font-size: 20px;
        margin-bottom: 25px;
        margin-top: 0;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .carousel .slide {
    img {
      height: auto;
    }
  }
  .slider {
    li {
      height: 403px;
      .centerText .slideContent {
        h1 {
          font-size: 45px;
        }
      }
      .sideContext {
        h1 {
          font-size: 60px;
        }
        h2 {
          font-size: 38px;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .carousel .slide {
    img {
      height: 195px;
    }
  }
  .slider {
    li {
      height: 195px;
      .centerText .slideContent {
        h1 {
          font-size: 35px;
        }
      }
      .sideContext {
        h1 {
          font-size: 30px;
        }
        h2 {
          font-size: 25px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
}
