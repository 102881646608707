@import "../../variables.scss";

.navWrapper {
  position: relative;
}

.nav {
  border-bottom: none;
  list-style: none;
  column-gap: 20px;
  padding: 0;
  display: flex;
  li {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 3px;
    border-bottom: 2px solid transparent;
    a {
      color: rgba(0, 0, 0, 0.88);
      &:hover {
        color: #0e9210;
      }
    }
    &.selected {
      border-bottom: 2px solid #0e9210;
      a {
        color: #0e9210;
        &:hover {
          color: #0e9210;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .nav {
    position: absolute;
    top: 40px;
    right: 15px;
    width: 200px;
    background: #ffffff;
    flex-direction: column;
    padding: 10px;
    z-index: 2;
    box-shadow: 0 2px 10px rgba($color: #000000, $alpha: 0.3);
    border-radius: 5px;
    display: none;
    li {
      padding: 8px;
    }
  }
}
.navMenu {
  display: none;
  flex-direction: column;
  row-gap: 8px;
  width: 25px;
  height: 25px;
  padding: 10px;
  border: 2px solid #bababa;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  span {
    background-color: #0e9210;
    height: 2px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .navMenu {
    display: flex;
  }
}
.ant-menu.ant-menu-horizontal {
  .ant-menu-item-selected {
    color: $green-color;
  }
}

.ant-menu.ant-menu-horizontal {
  .ant-menu-item {
    &:hover:not(.ant-menu-item-selected) {
      color: $green-color;
      &::after {
        border-bottom-color: $green-color;
      }
    }
  }
  .ant-menu-item-selected {
    &::after {
      border-bottom-color: $green-color;
    }
    &:hover {
      &::after {
        border-bottom-color: $green-color;
      }
    }
  }
}
