@import "./variables.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}
p {
  line-height: 25px;
  color: $paraghraph-color;
}
#root {
  display: flex;
  flex-direction: column;
}
.container {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
    margin: auto;
  }
}

.primary_btn,
.ant-btn-primary {
  background: $primary-color;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 6px;
  height: 40px;
  padding: 0px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    color: #ffffff;
    background: $green-color;
  }
}
