.innerHeader {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  h1 {
    font-size: 35px;
    margin: 0;
  }
  p {
    margin: 10px 0 0;
    color: #ffffff;
  }
}

@media screen and (max-width: 767px) {
  .innerHeader {
    padding: 0 15px;
    h1 {
      font-size: 25px;
    }
  }
}
