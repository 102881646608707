@import "../../variables.scss";
.homeAboutContent {
  padding: 50px 0;
  h4 {
    color: $green-color;
  }
  p {
    color: $paraghraph-color;
    line-height: 25px;
    text-align: justify;
  }
  .aboutImage {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .homeAboutContent {
    .aboutContent {
      margin-bottom: 15px;
    }
  }
}
