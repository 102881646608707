@import "../../variables.scss";
.homeTestimonialContent {
  background-image: url("../../../public/images/testimonial-bg.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  height: 649px;
  .container {
    padding: 30px 0;
  }
  h1 {
    font-size: 50px;
    text-transform: capitalize;
    position: relative;
    span {
      color: $orange-color;
    }
    img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  p {
    color: $paraghraph-color2;
    line-height: 25px;
    text-align: justify;
    margin-top: 0;
    font-style: italic;
  }
  h2 {
    font-size: 17px;
  }
  .slick-slider {
    margin-top: 50px;
    padding: 15px 15px 30px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0 1px 19px rgba($color: #000000, $alpha: 0.2);
  }
}

.slick-prev,
.slick-next {
  bottom: -6px;
  top: auto;
  &::before {
    color: #000000;
  }
}
.slick-prev {
  left: 15px;
}
.slick-next {
  left: 38px;
}

@media screen and (max-width: 767px) {
  .homeTestimonialContent {
    height: auto;
    margin-bottom: 15px;
    background-image: none;
    h1 {
      font-size: 30px;
      img {
        width: 30px;
      }
    }
    .testimonialImg {
      width: 100%;
    }
  }
}
